<template>
  <footer class="footer">
    <div class="row  pos-relative  z-index-1">
      <div class="col-6  col-md-12  col-sm-24  mb-md-m  mb-sm-s">
        <Logo>
          <img src="@/assets/logo.png">
        </Logo>
      </div>
      <div class="col-6  col-md-12  col-sm-24  mb-md-m  mb-sm-s">
        <div class="footer-label">{{ $t('footer.news.headline') }}</div>
        <p>
          <router-link :to="{ name: 'Login', params: { lang: $i18n.locale } }" v-slot="{ href }" class="font-size-base  -thin  -white  mb-xs">
            <AnchorLink class="display-flex" :href="href">
              {{ $t('footer.news.login') }}
              <Icon class="-is-right">
                <ArrowSVG></ArrowSVG>
              </Icon>
            </AnchorLink>
          </router-link>
          <router-link :to="{ name: 'Signup', params: { lang: $i18n.locale } }" v-slot="{ href }" class="font-size-base  -thin  -white  mb-xs">
            <AnchorLink class="display-flex" :href="href">
              {{ $t('footer.news.registerNow') }}
              <Icon class="-is-right">
                <ArrowSVG></ArrowSVG>
              </Icon>
            </AnchorLink>
          </router-link>
          <router-link :to="{ name: 'Login', params: { lang: $i18n.locale } }" v-slot="{ href }" class="font-size-base  -thin  -white  mb-xs">
            <AnchorLink class="display-flex" :href="href">
              {{ $t('footer.news.homepage') }}
              <Icon class="-is-right">
                <ArrowSVG></ArrowSVG>
              </Icon>
            </AnchorLink>
          </router-link>
        </p>
      </div>
      <div class="col-6  col-md-12  col-sm-24  mb-md-m  mb-sm-s">
        <div class="footer-label">{{ $t('footer.contact') }}</div>
        <p class="font-size-base">
          {{ $t('taskforce.address.name') }}<br>
          {{ $t('taskforce.address.company') }}<br>
          {{ $t('taskforce.address.street') }}<br>
          {{ $t('taskforce.address.city') }}
        </p>
        <p class="font-size-base">
          <AnchorLink class="-white" :href="`tel:${this.$t('taskforce.address.phone.link')}`">{{ $t('taskforce.address.phone.text') }}</AnchorLink><br>
          <AnchorLink class="-white" :href="`mailto:${this.$t('taskforce.address.email')}`">{{ $t('taskforce.address.email') }}</AnchorLink>
        </p>
      </div>
      <div class="col-6  col-md-12  col-sm-24">
        <div class="footer-label">{{ $t('footer.legalities.headline') }}</div>
        <p class="font-size-base">
          <AnchorLink class="display-flex  -white  mb-xs" :href="this.$t('footer.urls.imprint')">
            {{ $t('footer.legalities.imprint') }}
            <Icon class="-is-right">
              <ArrowSVG></ArrowSVG>
            </Icon>
          </AnchorLink>
          <AnchorLink class="display-flex  -white  mb-xs" :href="this.$t('footer.urls.dataProtection')">
            {{ $t('footer.legalities.dataProtection') }}
            <Icon class="-is-right">
              <ArrowSVG></ArrowSVG>
            </Icon>
          </AnchorLink>
          <AnchorLink class="display-flex  -white  mb-xs" :href="this.$t('footer.urls.agb')">
            {{ $t('footer.legalities.agb') }}
            <Icon class="-is-right">
              <ArrowSVG></ArrowSVG>
            </Icon>
          </AnchorLink>
        </p>
      </div>
    </div>
    <img class="footer-image" src="@/assets/graphics/footer.png" alt="">
  </footer>
</template>

<script>
import Logo from '@/components/atoms/Logo.vue';
import AnchorLink from '@/components/atoms/Link.vue';
import Icon from '@/components/atoms/Icon.vue';
import ArrowSVG from '@/assets/icons/arrow.svg';

export default {
  name: 'Footer',
  components: {
    Logo,
    Icon,
    AnchorLink,
    ArrowSVG,
  },
};
</script>

<style scoped lang="scss" src="@/sass/08_modules/footer.scss"></style>
