<template>
  <div class="site">
    <HeaderBarPublic></HeaderBarPublic>
    <main class="public">
      <div class="public-site-inner">
        <keep-alive :exclude="['ProjectDetail', 'PublicProjectDetail', 'AdminProjectDetail', 'UserProjectDetail']">
          <router-view></router-view>
        </keep-alive>
      </div>
      <Footer></Footer>
    </main>
  </div>
</template>

<script>
import HeaderBarPublic from '@/components/modules/HeaderBarPublic.vue';
import Footer from '@/components/modules/Footer.vue';

export default {
  name: 'Public',
  components: {
    HeaderBarPublic,
    Footer,
  },
};
</script>
